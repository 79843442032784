<template>
  <section class="terms" data-nosnippet>
    <div class="terms-container">
    <div>
      <router-link class="router-link" to="/">
          <div class="footer-logo-container">
            <div class="footer-brand-image"></div>
          </div>
      </router-link>
    </div>
    <div class="terms-content">
      <div class="terms-title title-text highlighted-text">Allgemeine<br> Gesch&auml;ftsbedingungen</div>
        <p><strong>1. Geltungsbereich</strong></p> 
        <p>Für alle Bestellungen über unseren Online-Shop gelten die nachfolgenden AGB. Unser Online-Shop richtet sich ausschließlich an Verbraucher.</p>
        <p>Verbraucher ist jede natürliche Person, die ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden können. Unternehmer ist eine natürliche oder juristische Person oder eine rechtsfähige Personengesellschaft, die bei Abschluss eines Rechtsgeschäfts in Ausübung ihrer gewerblichen oder selbständigen beruflichen Tätigkeit handelt.<br></p> 
        <br>
        <p><strong>2. Vertragspartner, Vertragsschluss, Korrekturm&ouml;glichkeiten</strong></p> 
        <p>Der Kaufvertrag kommt zustande mit Pleasmile UG (haftungsbeschränkt).</p> 
        <p>Mit Einstellung der Produkte in den Online-Shop geben wir ein verbindliches Angebot zum Vertragsschluss über diese Artikel ab. Sie können zunächst unverbindlich die gewünschte Stückzahl unserer Produkte festlegen und dann mit dem Zahlungsvorgang starten. Im Zahlungsvorgang können Sie die Positionen der Bestellung zur Kontrolle einsehen. Eine Korrektur ist durch Abbruch des Zahlungsvorgangs und anschließender Anpassung der gewünschten Stückzahlen je Artikel möglich. Der Vertrag kommt durch Abschluss des Zahlungsvorgangs zustande. Unmittelbar nach dem Absenden der Bestellung erhalten Sie noch einmal eine Bestätigung per E-Mail.<br></p> 
        <br>
        <p><strong>3. Vertragssprache, Vertragstextspeicherung</strong></p> 
        <p>Die f&uuml;r den Vertragsschluss zur Verf&uuml;gung stehende(n) Sprache(n): Deutsch</p>
        <p>Wir speichern den Vertragstext und senden Ihnen die Bestelldaten in Textform (Auftragsbestätigung &amp; Rechnung als PDF) zu.<br>
        <br>
        <p><strong>4. Lieferbedingungen</strong></p> 
        <p>Zuzüglich zu den angegebenen Produktpreisen kommen noch Versandkosten hinzu. Näheres zur Höhe der Versandkosten erfahren Sie bei den Angeboten.</p> 
        <p>Wir liefern nur im Versandweg. Eine Selbstabholung der Ware ist leider nicht m&ouml;glich.</p> 
        <br>
        <p><strong>5. Bezahlung</strong></p> 
        <p>In unserem Shop stehen Ihnen grunds&auml;tzlich die folgenden Zahlungsarten, welche durch den Zahlungsdienstleister PayPal (Europe) S.à r.l. et Cie, S.C.A, 22-24 Boulevard Royal, L-2449 Luxembourg („PayPal“) abgewickelt werden zur Verf&uuml;gung</p> 
        <p><strong>Kreditkarte</strong><br> 
        Im Bestellprozess geben Sie Ihre Kreditkartendaten an.  Ihre Karte wird unmittelbar nach Abgabe der Bestellung belastet.</p> 
        <p><strong>SEPA-Lastschriftverfahren</strong><br>
        Mit Abgabe der Bestellung erteilen Sie uns bzw. dem Zahlungsdienstleister PayPal (Europe) S.à r.l. et Cie, S.C.A, 22-24 Boulevard Royal, L-2449 Luxembourg („PayPal“) ein SEPA-Lastschriftmandat.<br></p> 
        <p><strong>PayPal</strong><br> 
        Um den Rechnungsbetrag über den Zahlungsdienstleister PayPal (Europe) S.à r.l. et Cie, S.C.A, 22-24 Boulevard Royal, L-2449 Luxembourg („PayPal“) bezahlen zu können, müssen Sie bei PayPal registriert sein, sich mit Ihren Zugangsdaten legitimieren und die Zahlungsanweisung bestätigen. Die Zahlungstransaktion wird durch PayPal unmittelbar nach Abgabe der Bestellung durchgeführt. Weitere Hinweise erhalten Sie im Bestellvorgang.</p>
        <p><strong>Sofort by Klarna</strong><br> Um den Rechnungsbetrag über den Zahlungsdienstleister Sofort GmbH, Theresienhöhe 12, 80339 München bezahlen zu können, müssen Sie über ein für Online-Banking freigeschaltetes Bankkonto verfügen, sich entsprechend legitimieren und die Zahlungsanweisung bestätigen. Ihr Konto wird unmittelbar nach Abgabe der Bestellung belastet. Weitere Hinweise erhalten Sie im Bestellvorgang.</p>  
        <p><strong>giropay / paydirekt</strong><br>
        Über den Zahlungsdienstleister PayPal (Europe) S.à r.l. et Cie, S.C.A, 22-24 Boulevard Royal, L-2449 Luxembourg („PayPal“) bieten wir die Zahlungsarten giropay und paydirekt des Zahlungsdienstleisters paydirekt GmbH, Stephanstr. 14-16, 60313 Frankfurt a. M an.<br>
        <div class="terms-indent">
        <strong>giropay</strong><br>
        Um den Rechnungsbetrag &uuml;ber giropay bezahlen zu k&ouml;nnen, m&uuml;ssen Sie &uuml;ber ein f&uuml;r Online-Banking freigeschaltetes Bankkonto verf&uuml;gen, sich entsprechend legitimieren und die Zahlungsanweisung best&auml;tigen. Ihr Konto wird unmittelbar nach Abgabe der Bestellung belastet. Weitere Hinweise erhalten Sie im Bestellvorgang.<br>
        <br>
        <strong>paydirekt</strong><br> 
        Um den Rechnungsbetrag &uuml;ber paydirekt bezahlen zu k&ouml;nnen, m&uuml;ssen Sie &uuml;ber ein f&uuml;r Online-Banking freigeschaltetes Bankkonto verf&uuml;gen, bei paydirekt registriert sein, sich mit Ihren Zugangsdaten legitimieren und die Zahlungsanweisung best&auml;tigen. Die Zahlungstransaktion wird unmittelbar nach Abgabe der Bestellung durchgef&uuml;hrt. Weitere Hinweise erhalten Sie im Bestellvorgang.</p>
        </div>
        <br>
        <strong>6. Eigentumsvorbehalt</strong><br>
        <p>Die Ware bleibt bis zur vollst&auml;ndigen Bezahlung unser Eigentum.</p>
        <br>
        <strong>7. Transportsch&auml;den</strong><br>
        <p>Werden Waren mit offensichtlichen Transportschäden angeliefert, so reklamieren Sie solche Fehler bitte möglichst sofort beim Zusteller und nehmen Sie bitte unverzüglich Kontakt zu uns auf. Die Versäumung einer Reklamation oder Kontaktaufnahme hat für Ihre gesetzlichen Ansprüche und deren Durchsetzung, insbesondere Ihre Gewährleistungsrechte, keinerlei Konsequenzen. Sie helfen uns aber, unsere eigenen Ansprüche gegenüber dem Frachtführer bzw. der Transportversicherung geltend machen zu können.<br></p>
        <br>
        <strong>8. Gew&auml;hrleistung und Garantien​​​​​​​</strong><br>
        <div class="terms-indent">
          <strong>8.1 M&auml;ngelhaftungsrecht</strong><br> 
        <p>Es gilt das gesetzliche Mängelhaftungsrecht.</p><br>
        <strong>8.2 Garantien und Kundendienst</strong><br> 
        <p>Informationen zu gegebenenfalls geltenden zusätzlichen Garantien und deren genaue Bedingungen finden Sie jeweils beim Produkt und auf besonderen Informationsseiten im Online-Shop.</p>
        </div>
        <br>
        <strong>9. Haftung​​​​​​​</strong><br>
        <p>F&uuml;r Anspr&uuml;che aufgrund von Sch&auml;den, die durch uns, unsere gesetzlichen Vertreter oder Erf&uuml;llungsgehilfen verursacht wurden, haften wir stets unbeschr&auml;nkt</p> 
        <ul> 
          <li>bei Verletzung des Lebens, des K&ouml;rpers oder der Gesundheit,</li> 
          <li>bei vors&auml;tzlicher oder grob fahrl&auml;ssiger Pflichtverletzung,</li> 
          <li>bei Garantieversprechen, soweit vereinbart, oder</li> 
          <li>soweit der Anwendungsbereich des Produkthaftungsgesetzes er&ouml;ffnet ist.</li> 
        </ul> 
        <p>Bei Verletzung wesentlicher Vertragspflichten, deren Erf&uuml;llung die ordnungsgem&auml;&szlig;e Durchf&uuml;hrung des Vertrages &uuml;berhaupt erst erm&ouml;glicht und auf deren Einhaltung der Vertragspartner regelm&auml;&szlig;ig vertrauen darf, (Kardinalpflichten) durch leichte Fahrl&auml;ssigkeit von uns, unseren gesetzlichen Vertretern oder Erf&uuml;llungsgehilfen ist die Haftung der H&ouml;he nach auf den bei Vertragsschluss vorhersehbaren Schaden begrenzt, mit dessen Entstehung typischerweise gerechnet werden muss.<br> 
        Im &Uuml;brigen sind Anspr&uuml;che auf Schadensersatz ausgeschlossen.</p> 
        <br>
        <strong>10. Streitbeilegung​​​​​​​</strong><br>
        <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie <a class="external-link" href="https://ec.europa.eu/consumers/odr/" target="_blank">hier</a> finden. Zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle sind wir nicht verpflichtet und nicht bereit.<br>
        <p>Diese AGB basiert auf dem <a class="external-link" target="_blank" title="Trusted Shops" href="https://legal.trustedshops.com/">Trusted Shops</a> Rechtstexter in Kooperation mit <a target="_blank" title="FÖHLISCH Rechtsanwälte" class="external-link" href="https://foehlisch.com" rel="nofollow">FÖHLISCH Rechtsanwälte</a>. Es wurden individuelle Anpassungen durch Pleasmile vorgenommen.</p>
      </div>
      <Footer />
    </div>
  </section>
</template>

<script>
import Footer from "@/components/Footer.vue";

export default {
  name: "Terms",
  components: {
    Footer,
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/colors.scss";

.terms {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background-color: $page-background-color-primary-dark;
}

.terms-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
}

.terms-content {
  font-family: Dosis;
  font-weight: 400;
  font-size: 18px;
  color: $text-color-primary-light;
  max-width: 600px;
  margin: 40px 40px 40px 40px;
}

.terms-title {
  display: inline-block;
  margin-bottom: 40px;
}

.terms-indent {
  margin-top: 16px;
  margin-left: 16px;
}
</style>
